import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionsGuard } from '@thg-procure-team/procure-common-ui/platform/security';

import { PermissionLevel } from '@procure-user/core/user/domain';
import { LayoutNotFoundComponent } from '@procure-user/core/layouts/layout-not-found/layout-not-found.component';
import { DelegationsGuard } from '@procure-user/feature/delegations/guard/delegations.guard';

const routes: Routes = [
  {
    path: 'delegations',
    loadChildren: () => import('./feature/delegations/delegations.module').then((m) => m.DelegationsModule),
  },
  {
    path: 'delegations-management',
    loadChildren: () =>
      import('./feature/delegations-management/delegations-management.module').then(
        (m) => m.DelegationsManagementModule,
      ),
    data: { permissions: { Delegations: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./feature/dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: { permissions: { 'User Dashboard': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'reports/management/upload',
    loadChildren: () =>
      import('./feature/report-definitions-upload/report-definitions-upload.module').then(
        (m) => m.ReportDefinitionsUploadModule,
      ),
    data: { permissions: { 'Reports Management': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'reports/management/:id',
    loadChildren: () =>
      import('./feature/dashboard-reports/dashboard-reports.module').then((m) => m.DashboardReportsModule),
    data: {
      permissions: { 'Reports Management': PermissionLevel.FULL_ALL_BUSINESS_UNITS },
    },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'reports/management',
    loadChildren: () =>
      import('./feature/reports-management/reports-management.module').then((m) => m.ReportsManagementModule),
    data: { permissions: { 'Reports Management': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'reports/groups',
    loadChildren: () =>
      import('./feature/report-definition-groups/report-definition-groups.module').then(
        (m) => m.ReportDefinitionGroupsModule,
      ),
    data: { permissions: { 'Report Groups': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./feature/dashboard-reports/dashboard-reports.module').then((m) => m.DashboardReportsModule),
    data: { permissions: { Reports: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'roles',
    loadChildren: () => import('./feature/roles/roles.module').then((m) => m.RolesModule),
    data: { permissions: { Roles: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'roles/:id',
    loadChildren: () => import('./feature/role-form/role-form.module').then((m) => m.RoleFormModule),
    data: { permissions: { Roles: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'users',
    loadChildren: () => import('./feature/users/users.module').then((m) => m.UsersModule),
    data: { permissions: { Users: PermissionLevel.READ_ONLY_BUSINESS_UNIT } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'users/:id',
    loadChildren: () => import('./feature/user-form/user-form.module').then((m) => m.UserFormModule),
    data: { permissions: { Users: PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'supplier-products',
    loadChildren: () =>
      import('./feature/supplier-products/supplier-products.module').then((m) => m.SupplierProductsModule),
    data: { permissions: { 'Supplier Products & Prices': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'indirect-supplier-products',
    loadChildren: () =>
      import('./feature/supplier-products/supplier-products.module').then((m) => m.SupplierProductsModule),
    data: { permissions: { 'Indirect Supplier Products': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'supplier-products/csv-upload',
    loadChildren: () =>
      import('./feature/supplier-product-csv-upload/supplier-product-csv-upload.module').then(
        (m) => m.SupplierProductCsvUploadModule,
      ),
    data: { permissions: { 'Supplier Products & Prices': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'supplier-products/bulk-delete',
    loadChildren: () =>
      import('./feature/supplier-product-bulk-delete/supplier-product-bulk-delete.module').then(
        (m) => m.SupplierProductBulkDeleteModule,
      ),
    data: { permissions: { 'Supplier Products & Prices': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'supplier-emails',
    loadChildren: () =>
      import('./feature/supplier-emails/supplier-emails.module').then((m) => m.SupplierEmailsModule),
    data: { permissions: { Users: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/requisitions/csv-upload',
    loadChildren: () =>
      import('./feature/requisition-form-csv-upload/requisition-form-csv-upload.module').then(
        (m) => m.RequisitionFormCsvUploadModule,
      ),
    data: { permissions: { 'Requisitions List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/requisitions/add',
    loadChildren: () =>
      import('./feature/requisition-form/requisition-form.module').then((m) => m.RequisitionFormModule),
    data: { permissions: { 'Requisitions List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/requisitions/:id',
    data: { permissions: { 'Requisitions List': PermissionLevel.READ_ONLY_BUSINESS_UNIT } },
    canLoad: [PermissionsGuard, DelegationsGuard],
    loadChildren: () =>
      import('./feature/requisition-details-container/requisition-details-container.module').then(
        (m) => m.RequisitionDetailsContainerModule,
      ),
  },
  {
    path: 'requisition-workflows/:workflowId/requisitions',
    loadChildren: () => import('./feature/requisitions/requisitions.module').then((m) => m.RequisitionsModule),
    data: { permissions: { 'Requisitions List': PermissionLevel.READ_ONLY_BUSINESS_UNIT } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/:id/discrepancies-upload',
    loadChildren: () =>
      import('./feature/purchase-order-upload-discrepancies/purchase-order-upload-discrepancies.module').then(
        (m) => m.PurchaseOrderUploadDiscrepanciesModule,
      ),
    data: { permissions: { 'Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/:id/asn-prices-upload',
    loadChildren: () =>
      import('./feature/purchase-order-prices-csv-upload/purchase-order-prices-csv-upload.module').then(
        (m) => m.PurchaseOrderPricesCsvUploadModule,
      ),
    data: { permissions: { 'Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/:id',
    loadChildren: () =>
      import('./feature/purchase-order-details/purchase-order-details.module').then(
        (m) => m.PurchaseOrderDetailsModule,
      ),
    data: { permissions: { 'Purchase Order List': PermissionLevel.READ_ONLY_BUSINESS_UNIT } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/delivery-windows/csv-upload',
    loadChildren: () =>
      import(
        './feature/purchase-orders-delivery-windows-bulk-upload/purchase-orders-delivery-windows-bulk-upload.module'
      ).then((m) => m.PurchaseOrdersDeliveryWindowsBulkUploadModule),
    data: { permissions: { 'Purchase Order List': PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders',
    loadChildren: () =>
      import('./feature/purchase-orders/purchase-orders.module').then((m) => m.PurchaseOrdersModule),
    data: { permissions: { 'Purchase Order List': PermissionLevel.READ_ONLY_BUSINESS_UNIT } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'deliveries',
    loadChildren: () => import('./feature/deliveries/deliveries.module').then((m) => m.DeliveriesModule),
    data: { permissions: { Deliveries: PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'deliveries/:id/asns/:asnId',
    loadChildren: () =>
      import('./feature/delivery-asn-details/delivery-asn-details.module').then((m) => m.DeliveryAsnDetailsModule),
    data: { permissions: { Deliveries: PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'deliveries/:id/non-compliance-report',
    data: { permissions: { 'Non-Compliance Record': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
    loadChildren: () =>
      import('./feature/non-compliance-report-details/non-compliance-report-details.module').then(
        (m) => m.NonComplianceReportDetailsModule,
      ),
  },
  {
    path: 'deliveries/:id',
    loadChildren: () =>
      import('./feature/delivery-details/delivery-details.module').then((m) => m.DeliveryDetailsModule),
    data: { permissions: { Deliveries: PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'business-units',
    loadChildren: () => import('./feature/business-unit/business-units.module').then((m) => m.BusinessUnitsModule),
    data: { permissions: { 'Business Units': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'departments',
    loadChildren: () => import('./feature/departments/departments.module').then((m) => m.DepartmentsModule),
    data: { permissions: { Departments: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'manual-suppliers-setup',
    loadChildren: () =>
      import('./feature/manual-suppliers-setup/manual-suppliers-setup.module').then(
        (m) => m.ManualSuppliersSetupModule,
      ),
    data: { permissions: { 'Manual supplier setup': PermissionLevel.READ_ONLY_BUSINESS_UNIT } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'manual-suppliers-setup/:id',
    data: { permissions: { 'Manual supplier setup': PermissionLevel.READ_ONLY_BUSINESS_UNIT } },
    canLoad: [PermissionsGuard, DelegationsGuard],
    loadChildren: () =>
      import('./feature/manual-suppliers-setup-details/manual-suppliers-setup-details.module').then(
        (m) => m.ManualSuppliersSetupDetailsModule,
      ),
  },
  {
    path: 'requisition-workflows/:workflowId/invoices/:id/discrepancies-upload',
    loadChildren: () =>
      import('./feature/invoice-upload-discrepancies/invoice-upload-discrepancies.module').then(
        (m) => m.InvoiceUploadDiscrepanciesModule,
      ),
    data: { permissions: { 'Invoice price discrepancy fix': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/invoices',
    loadChildren: () => import('./feature/invoices/invoices.module').then((m) => m.InvoicesModule),
    data: {
      permissions: {
        'P2P invoices list': PermissionLevel.READ_ONLY_BUSINESS_UNIT,
        'IWT Invoices list': PermissionLevel.READ_ONLY_BUSINESS_UNIT,
      },
    },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/invoices/upload/:id',
    loadChildren: () =>
      import('./feature/invoice-upload/invoice-upload.module').then((m) => m.InvoiceUploadModule),
    data: { permissions: { 'Invoice upload': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/invoices/upload',
    loadChildren: () =>
      import('./feature/invoice-upload/invoice-upload.module').then((m) => m.InvoiceUploadModule),
    data: { permissions: { 'Invoice upload': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/invoices/:id',
    loadChildren: () =>
      import('./feature/invoice-details/invoice-details.module').then((m) => m.InvoiceDetailsModule),
    data: {
      permissions: {
        'P2P invoices list': PermissionLevel.READ_ONLY_BUSINESS_UNIT,
        'IWT Invoices list': PermissionLevel.READ_ONLY_BUSINESS_UNIT,
      },
    },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'product-groups',
    loadChildren: () =>
      import('./feature/product-groups/product-groups.module').then((m) => m.ProductGroupsModule),
    data: { permissions: { 'Product Groups': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'products',
    loadChildren: () => import('./feature/products/products.module').then((m) => m.ProductsModule),
    data: { permissions: { Products: PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'approval-limits',
    loadChildren: () =>
      import('./feature/approval-limits/approval-limit.module').then((m) => m.ApprovalLimitModule),
    data: { permissions: { 'Approval Limits': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-workflows',
    loadChildren: () =>
      import('./feature/requisition-workflows/requisition-workflows.module').then(
        (m) => m.RequisitionWorkflowsModule,
      ),
    data: { permissions: { 'Requisition Workflows': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-types',
    loadChildren: () =>
      import('./feature/requisition-types/requisition-type.module').then((m) => m.RequisitionTypeModule),
    data: { permissions: { 'Requisition Types': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'requisition-purposes',
    loadChildren: () =>
      import('./feature/requisition-purposes/requisition-purpose.module').then((m) => m.RequisitionPurposeModule),
    data: { permissions: { 'Requisition Purposes': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'invoice-auto-assign-mapping/upload',
    loadChildren: () =>
      import('./feature/invoice-auto-assign-mapping-upload/invoice-auto-assign-mapping-upload.module').then(
        (m) => m.InvoiceAutoAssignMappingUploadModule,
      ),
    data: { permissions: { 'Invoice assignment': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'invoice-auto-assign-mapping',
    loadChildren: () =>
      import('./feature/invoice-auto-assign-mapping/invoice-auto-assign-mapping.module').then(
        (m) => m.InvoiceAutoAssignMappingModule,
      ),
    data: { permissions: { 'Invoice assignment': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'funding-requests',
    loadChildren: () =>
      import('./feature/funding-requests/funding-requests.module').then((m) => m.FundingRequestsModule),
    data: { permissions: { 'Funding Request - View': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'funding-requests/csv-upload',
    loadChildren: () =>
      import('./feature/funding-request-form-csv-upload/funding-request-form-csv-upload.module').then(
        (m) => m.FundingRequestFormCsvUploadModule,
      ),
    data: { permissions: { 'Funding Request - Create & Amend': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'funding-requests/add',
    loadChildren: () =>
      import('./feature/funding-request-form/funding-request-form.module').then((m) => m.FundingRequestFormModule),
    data: { permissions: { 'Funding Request - Create & Amend': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
  },
  {
    path: 'funding-requests/:id',
    data: { permissions: { 'Funding Request - View': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, DelegationsGuard],
    loadChildren: () =>
      import('./feature/funding-request-details-container/funding-request-details-container.module').then(
        (m) => m.FundingRequestDetailsContainerModule,
      ),
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '404', component: LayoutNotFoundComponent },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
